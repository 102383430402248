<template>
  <div class="requests page">
    <div class="content">
      <div class="page_header">
        <h1>Обращения</h1>
        <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Новое обращение</el-button>
      </div>
      <div id="filter">
        <div class="status ib">
          <el-select
              v-model="filter.status"
              multiple
              collapse-tags
              :loading="statusesLoading"
              size="mini"
              placeholder="Статус обращения"
              @change="changeFilter">
            <el-option
                v-for="status in statuses"
                :key="status.id"
                :label="status.title"
                :value="status.id">
                <span
                    :style="{
                    backgroundColor: status.bg,
                    display: 'inline-block',
                    verticalAlign: 'top',
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    marginTop: '8px'
                  }"></span>{{ status.title }}
            </el-option>
          </el-select>
        </div>
        <div class="query ib">
          <el-input
              v-model="filter.query"
              clearable
              size="mini"
              @keyup.enter.native="changeFilter"
              @change="changeFilter"
              placeholder="Поиск по номеру телефона, имени клиента и т.д."
          ></el-input>
        </div>
        <div class="visibility ib">
          <el-radio-group v-model="filter.visibility" size="mini" @change="changeFilter">
            <el-radio-button label="my">Только мои</el-radio-button>
            <el-radio-button label="office">Мой офис</el-radio-button>
            <el-radio-button label="all">Все обращения</el-radio-button>
          </el-radio-group>
        </div>
        <div class="date_range ib">
          <el-date-picker
              clearable
              v-model="filter.dateRange"
              type="daterange"
              align="left"
              size="mini"
              unlink-panels
              format="dd.MM.yyyy"
              value-format="dd.MM.yyyy"
              start-placeholder="Дата от"
              end-placeholder="до"
              :picker-options="datePickerOptions"
              @change="changeFilter"></el-date-picker>
        </div>
      </div>
      <div class="box">
        <div class="grid" v-loading="loading">
          <div
              class="request grid_row"
              v-for="request in requests"
              :key="request.id">
            <el-row :gutter="0">
              <el-col :xs="15" :sm="4" :offset="0">
                <div class="client">
                  <el-link
                      @click.stop.prevent
                      :underline="false"
                      class="client_link"
                      icon="el-icon-user">
                    <!-- <el-avatar size="small"> {{request.client.name[0] }} </el-avatar> -->
                    <span class="client_name">{{ request.client.name }}</span>
                  </el-link>
                </div>
                <div class="time">
                  <i class="el-icon-time"></i>
                  {{ moment(request.created_at).format("DD.MM.YYYY HH:mm") }}
                </div>
              </el-col>
              <el-col :xs="9" :sm="3" :offset="0">
                <div class="contacts">
                  <template v-if="request.client.contacts">
                    <div
                        class="contact"
                        v-for="contact in request.client.contacts"
                        :key="contact.id">
                      <template class="" v-if="contact.type == 'phone'">
                        <el-link
                            :href="'tel:+7' + contact.formatted"
                            class="phone_link"
                            icon="el-icon-phone"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                      <template class="" v-if="contact.type == 'email'">
                        <el-link
                            :href="'mailto:' + contact.value"
                            class="email_link"
                            icon="el-icon-message"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                    </div>
                  </template>
                  <div class="contact empty" v-else>Нет контактов</div>
                </div>
              </el-col>
              <el-col :xs="15" :sm="7" :offset="0">
                <div class="calculations" v-if="request.calculations">
                  <el-tag
                      size="mini"
                      type="info"
                      class="calculation"
                      v-for="c in request.calculations"
                      :key="c.id"
                      :class="{ notapplicable: Boolean(!Number(c.applicable)) }">
                    <span class="title">Расчет №{{ c.number }}</span> -
                    <strong class="price">{{ (c.sum_wd) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="" v-else>Нет данных</div>
                <div class="orders" v-if="request.orders">
                  <el-tag
                      size="mini"
                      class="order"
                      v-for="o in request.orders"
                      :key="o.id"
                      :class="{ notapplicable: !o.applicable }"
                      :style="{
                    backgroundColor: o.status.bg,
                    borderColor: o.status.bg,
                    color: o.status.color,
                  }">
                    <span class="title">Заказ №{{ o.number }}</span>
                    -
                    <strong class="price">{{ (o.sum_wd) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="payments" v-if="request.payments">
                  <el-tag
                      size="mini"
                      type="success"
                      class="payment"
                      v-for="p in request.payments"
                      :key="p.id"
                      :class="{ notapplicable: !p.applicable }">
                    <span class="title">Платеж №{{ p.number }}</span>
                    -
                    <strong class="price">{{ (p.sum) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="comment" v-if="request.comment">
                  {{ request.comment }}
                </div>
              </el-col>
              <el-col :xs="9" :sm="5" :offset="0">
                <div class="status">
                  <el-tag
                      class="statue_badge"
                      size="small"
                      type="info"
                      v-if="request.status"
                      :style="{
                    backgroundColor: request.status.bg,
                    borderColor: request.status.bg,
                    color: request.status.color,
                  }">
                    {{ request.status.title }}
                  </el-tag>
                  <el-tag v-else class="status_badge" size="small" type="info">Статус удален, установите новый</el-tag>
                </div>
              </el-col>
              <el-col :xs="15" :sm="3" :offset="0">
                <el-link
                    class="employee"
                    v-if="request.employee"
                    icon="el-icon-user"
                    type="info"
                    :underline="false">
                  {{ request.employee.surname }}
                  <span v-if="request.employee.name">{{ request.employee.name[0] }}.</span>
                  <span v-if="request.employee.patronymic">{{ request.employee.patronymic[0] }}.</span>
                </el-link>
                <div class="employee" v-else>Нет ответственного</div>
              </el-col>
              <el-col :xs="9" :sm="2" :offset="0" :style="{ textAlign: 'right' }">
                <el-button
                    type="primary"
                    size="mini"
                    @click="openRequest(request.id)"
                    icon="el-icon-view">Детали
                </el-button>
              </el-col>
            </el-row>

          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="requestsSize"
            :page-size="filter.limit"
            :current-page="currentPage"
            :hide-on-single-page="true"
            @current-change="paginateChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="Новое обращение"
        :visible.sync="showCreator"
        width="300px"
        :before-close="closeCreator">
      <div class="newRequestBox">
        <div class="clientChanger" v-if="newRequestClientsList">
          <p>Этот номер указан у нескольких клиентовё, выберите нужного:</p>
          <span class="client" v-for="client in newRequestClientsList" @click="createByClientId(client.id)">
            {{ client.name }}
          </span>
        </div>
        <el-form :model="newRequest"
                 :rules="newRequestRules"
                 ref="newRequest"
                 class="form"
                 @submit.native.prevent="createRequest('newRequest')"
                 v-else
        >
          <el-form-item label="Номер телефона" prop="phone">
            <el-input
                v-model="newRequest.phone"
                v-facade="'+7 (###) ###-####'"
                placeholder="+7 (___) ___-____"
                size="mini"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeCreator">Отмена</el-button>
        <el-button v-if="!newRequestClientsList" size="mini" type="primary"
                   @click="createRequest('newRequest')">Создать</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as requestsActions} from "@/store/modules/requests";

import price from '@/filters/price'

import autocompleteApi from "@/api/autocomplete";

export default {
  name: "Requests",
  data() {
    let validatePhoneLength = (rule, value, callback) => {
      if (value.length < 17) {
        callback(new Error('Введите номер телефона полностью'))
      } else {
        callback()
      }
    }
    let validatePhoneFormat = (rule, value, callback) => {
      if (value[4] != '9') {
        callback(new Error('Введите правильный номер телефона'))
      } else {
        callback()
      }
    }
    return {
      filter: {
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 50,
        offset: this.$route.query.offset ? Number(this.$route.query.offset) : 0,
        status: this.$route.query.status ? this.$route.query.status : [],
        query: this.$route.query.query ? this.$route.query.query : '',
        visibility: this.$route.query.visibility ? this.$route.query.visibility : 'office',
        dateRange: [],
      },
      datePickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Неделя",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Месяц",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 месяца",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ]
      },
      showCreator: false,
      newRequest: {
        phone: '',
      },
      newRequestRules: {
        phone: [
          {
            required: true,
            message: 'Введите номер телефона',
            trigger: 'blur'
          },
          {validator: validatePhoneLength, trigger: 'blur'},
          {validator: validatePhoneFormat, trigger: 'blue'},
        ]
      },
      newRequestClientsList: null
    }
  },
  computed: {
    ...mapState({
      requests: state => _.orderBy(state.requests.list, ['created_at'], ['desc']),
      requestsSize: state => state.requests.size,
      loading: state => state.requests.loading,
      submitting: state => state.requests.submitting,
      statuses: state => state.statuses_for_requests.list,
      statusesLoading: state => state.statuses_for_requests.loading,
    }),
    currentPage() {
      return ((this.filter.offset + this.filter.limit) / this.filter.limit)
    },
  },
  currentPage() {
    return ((this.filter.offset + this.filter.limit) / this.filter.limit)
  },
  methods: {
    create() {
      this.showCreator = true
    },
    closeCreator() {
      this.showCreator = false
      this.newRequest.phone = ''
      this.newRequestClientsList = null
    },
    createRequest(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$store.dispatch(requestsActions.createRequest, this.newRequest).then((payload) => {
            if (payload.status === 'success') {
              this.openRequest(payload.request_id)
            } else if (payload.status === 'needChangeClient') {
              this.newRequestClientsList = payload.list
            }
          })
        } else {
          return false;
        }
      })
    },
    createByClientId(id) {
      this.$store.dispatch(requestsActions.createRequest, {clientId: id}).then((payload) => {
        if (payload.status === 'success') {
          this.openRequest(payload.request_id)
        }
      })
    },
    loadRequests() {
      this.$store.dispatch(requestsActions.get, this.filter)
    },
    clear() {
      this.$store.dispatch(requestsActions.clear)
    },
    paginateChange(page) {
      this.filter.offset = (page - 1) * this.filter.limit;
      this.changeRoute();
      this.loadRequests();
    },
    changeFilter() {
      this.filter.offset = 0
      this.changeRoute()
      this.loadRequests()
    },
    changeRoute() {
      this.$router
          .push({path: 'requests', query: this.filter})
          .catch((e) => {
            console.log('route not changed')
          })
    },
    openRequest(id) {
      this.$router.push({path: "/requests/" + id});
    },
  },
  mounted() {
    if (
        this.$route.query.dateRange != undefined &&
        typeof this.$route.query.dateRange == "object"
    ) {
      if (this.$route.query.dateRange.length > 0) {
        //TODO: Edit this code for set dates from string dormat
        this.filter.dateRange[0] = new Date(this.$route.query.dateRange[0]);
        this.filter.dateRange[1] = new Date(this.$route.query.dateRange[1]);
        console.log(this.filter.dateRange)
      }
    }
    this.loadRequests()
  },
  beforeDestroy() {
    this.clear()
  },
  filters: {
    price
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
